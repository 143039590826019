<template>
	<section class="content">
		<div class="card">
			<div class="card-body">
				<div class="row mb-3 justify-content-end" style="row-gap: .5rem;">
					<div class="col-12 col-sm-auto mr-auto mr-sm-0">
						<label class="control-label my-2 ml-2 font-weight-normal">Tanggal Upload :</label>
					</div>
					<div class="col-12 col-sm-auto mr-auto" :class="{ 'mr-sm-0' : false }">
						<div class="input-group">
							<input type="text" ref="daterange" class="form-control border-right-0" />
							<div class="input-group-append">
								<div class="input-group-text rounded-right d-flex align-items-center border-left-0 pl-1 bg-white">
									<i class="fi fi-rr-calendar d-flex"></i>
								</div>
							</div>
						</div>
					</div>
					<div class="col-auto col-sm-auto">
						<div class="input-group cursor-pointer" @click="selectFilter">
							<span class="form-control border-right-0">Filter</span>
							<div class="input-group-append">
								<div class="input-group-text rounded-right d-flex align-items-center border-left-0 pl-1 bg-white">
									<i class="fi fi-rr-filter d-flex"></i>
								</div>
							</div>
						</div>
					</div>
					<div class="col col-sm-auto text-right">
						<div class="btn-group" ref="toolbar">
							<button type="button" data-action="read" class="btn btn-outline-primary border-right-0">
								<i class="fi fi-rr-refresh d-flex"></i>
							</button>
							<button type="button" :disabled="loading" v-on:click="downloadXlsx" data-action="export-xlsx" class="btn btn-outline-primary">
								<i class="fi fi-rr-file-download"></i> Download
							</button>
						</div>
					</div>
				</div>

				<div class="row mb-3" style="row-gap: 15px;" v-if="filter_courier.length > 0 || filter_bank.length > 0">
					<div class="col-auto" v-for="data in filter_courier">
						<span class="btn border border-secondary rounded-pill pr-2 text-secondary">
							{{ data.label }} <span class="cursor-pointer" @click="removeFilterCourier(data.courier_id)">&#128473;</span>
						</span>
					</div>
					<div class="col-auto" v-for="data in filter_bank">
						<span class="btn border border-secondary rounded-pill pr-2 text-secondary">
							{{ data.label.split(' / ')[1] }} <span class="cursor-pointer" @click="removeFilterBank(data.rekening_id)">&#128473;</span>
						</span>
					</div>
					<div class="col-auto" v-if="filter_courier.length > 1 || filter_bank.length > 1 || (filter_courier.length > 0 && filter_bank.length > 0)">
						<span class="btn btn-link px-0 text-secondary cursor-pointer font-weight-bold" @click="clearFilter">
							Hapus Semua
						</span>
					</div>
				</div>

				<table class="table table-hover row-border" ref="tableRiwayatRekonsiliasi" id="tableRiwayatRekonsiliasi">
					<thead>
						<tr>
							<th>
								<input type="checkbox" id="checkall" ref="checkall" name="checkall" value="1" @click="selectAll" />
							</th>
							<th>Tanggal Upload</th>
							<th>Kurir</th>
							<th>Rekening</th>
							<th>Jumlah Resi</th>
							<th>Aksi</th>
						</tr>
					</thead>
					<tbody @click="handleClick">
					</tbody>
				</table>
			</div>
		</div>

		<div class="modal" tabindex="-1" role="dialog" ref="filterDialog" data-backdrop="static">
			<div class="modal-dialog" role="document">
				<div class="modal-content">
					<form @submit="getFilter($event)">
						<div class="modal-header">
							<h5 class="modal-title font-weight-bold">Filter Rekonsiliasi</h5>
							<button type="button" class="close" data-dismiss="modal" aria-label="Close">
								<span aria-hidden="true">&times;</span>
							</button>
						</div>
						<div class="modal-body">
							<div class="form-group m-2 p-3 bg-light rounded">
								<div class="row">
									<div class="col d-flex justify-content-between">
										<label class="h6">Kurir</label>
									</div>
								</div>
								<div class="grid grid-auto-flow-column" style="--bs-rows: 3;--bs-columns: 3;">
									<div v-for="data in listCourier">
										<div class="icheck-material-orange icheck-inline form-check w-100">
											<input class="form-check-input" type="checkbox" :id="data.prefix" :value="data.courier_id" v-model="filter.courier" />
											<label class="form-check-label text-uppercase w-100" :for="data.prefix">{{ data.label }}</label>
										</div>
									</div>
								</div>
							</div>
							<div class="form-group m-2 p-3 bg-light rounded">
								<div class="row">
									<div class="col d-flex justify-content-between">
										<label class="h6">Rekening</label>
									</div>
								</div>
								<div class="grid grid-auto-flow-column" style="--bs-rows: 3;--bs-columns: 3;">
									<div v-for="data in listBank">
										<div class="icheck-material-orange icheck-inline form-check w-100">
											<input class="form-check-input" type="checkbox" :id="data.code + '-' + data.rekening_id" :value="data.rekening_id" v-model="filter.bank" />
											<label class="form-check-label w-100" :for="data.code + '-' + data.rekening_id">{{ data.label.split(' / ')[1] }}</label>
										</div>
									</div>
								</div>
							</div>
						</div>
						<div class="modal-footer">
							<button type="button" class="btn btn-outline-primary" data-dismiss="modal">Batal</button>
							<button type="submit" class="btn btn-primary">Cari</button>
						</div>
					</form>
				</div>
			</div>
		</div>

		<div class="modal" tabindex="-1" role="dialog" ref="filterDialog" data-backdrop="static">
			<div class="modal-dialog" role="document">
				<div class="modal-content">
					<form @submit="getFilter($event)">
						<div class="modal-header">
							<h5 class="modal-title font-weight-bold">Filter Rekonsiliasi</h5>
							<button type="button" class="close" data-dismiss="modal" aria-label="Close">
								<span aria-hidden="true">&times;</span>
							</button>
						</div>
						<div class="modal-body">
							<div class="form-group m-2 p-3 bg-light rounded">
								<div class="row">
									<div class="col d-flex justify-content-between">
										<label class="h6">Kurir</label>
									</div>
								</div>
								<div class="grid grid-auto-flow-column" style="--bs-rows: 3;--bs-columns: 3;">
									<div v-for="data in listCourier">
										<div class="icheck-material-orange icheck-inline form-check w-100">
											<input class="form-check-input" type="checkbox" :id="data.prefix" :value="data.courier_id" v-model="filter.courier" />
											<label class="form-check-label text-uppercase w-100" :for="data.prefix">{{ data.label }}</label>
										</div>
									</div>
								</div>
							</div>
							<div class="form-group m-2 p-3 bg-light rounded">
								<div class="row">
									<div class="col d-flex justify-content-between">
										<label class="h6">Rekening</label>
									</div>
								</div>
								<div class="grid grid-auto-flow-column" style="--bs-rows: 3;--bs-columns: 3;">
									<div v-for="data in listBank">
										<div class="icheck-material-orange icheck-inline form-check w-100">
											<input class="form-check-input" type="checkbox" :id="data.code + '-' + data.rekening_id" :value="data.rekening_id" v-model="filter.bank" />
											<label class="form-check-label w-100" :for="data.code + '-' + data.rekening_id">{{ data.label.split(' / ')[1] }}</label>
										</div>
									</div>
								</div>
							</div>
						</div>
						<div class="modal-footer">
							<button type="button" class="btn btn-outline-primary" data-dismiss="modal">Batal</button>
							<button type="submit" class="btn btn-primary">Cari</button>
						</div>
					</form>
				</div>
			</div>
		</div>
	</section>
	<!-- /.content -->
</template>
<script>
import { createTable, authFetch, auth, formatCurrency,openChat } from "@/libs/hxcore";
import $ from "jquery";
import vSelect from "vue-select";
import "vue-select/dist/vue-select.css";
import moment from "moment";
import Daterangepicker from "daterangepicker";
import "daterangepicker/daterangepicker.css";
import Swal from "sweetalert2";
import "icheck-material";

export default {
	name: "Shipper",
	data() {
		return {
			dt1: moment().startOf("month"),
			dt2: moment(),
			listCourier: [],
			filter_courier: [],
			listBank: [],
			filter_bank: [],
			loading: false,
			errors: [],
			shipmentAmount: [
				{
					id: '5',
					label: '1 - 5'
				},
				{
					id: '30',
					label: '6 - 30'
				},
				{
					id: '100',
					label: '31 - 100'
				},
				{
					id: '1000',
					label: ' > 100'
				}
			],
			listMember: [],
			listCS: [],
			listMarking: [
				{
					id: '',
					label: '',
					hidden: false
				},
				{
					id: 'blacklist',
					label: 'Blacklist',
					hidden: false
				},
				{
					id: 'suspect',
					label: 'Suspect',
					hidden: false
				},
				{
					id: 'trust',
					label: 'Trust',
					hidden: false
				},
				{
					id: 'suspect rekening',
					label: 'Auto suspect rekening',
					hidden: true
				}
			],
			method: "",
			roles: "",
			filter: {
				courier: [],
				bank: [],
				dt1: moment().startOf("month"),
				dt2: moment()
			},
			name: "",
			value: "",
			additionals: [{
				name: "",
				value: "",
			}]
		};
	},
	computed: {},
	components: {
		vSelect,
	},
	watch:{},
	created: function () {
		this.roles = this.$route.meta.roles;
		this.userInfo = auth.user();

		if(this.$route.params.filter) {
			this.filter = this.$route.params.filter;
		}

		if(this.filter.dt1 && this.filter.dt2)
		{
			this.dt1 = moment(this.filter.dt1);
			this.dt2 = moment(this.filter.dt2);
		}
		this.dateStart = this.dt1.format('YYYY-MM-DD');
		this.dateEnd = this.dt2.format('YYYY-MM-DD');
		if(this.filter.gid)
		{
			this.groupId = this.filter.gid;
			this.dateStart = '';
			this.dateEnd = '';
		}
		if(Object.keys(this.filter).length<1)
		{
			if(sessionStorage.filterData)this.filter = JSON.parse(sessionStorage.filterData);
		}
		else{
			sessionStorage.filterData = JSON.stringify(this.filter);
			sessionStorage.filterStatus = 1;
		}
		this.filter.datatype = this.data_type;

		authFetch("/shipper/member_type").then(res => {
			res.json().then((json) => {
				this.listMember = json.data;
			});
		});

		authFetch("/shipper/kurir").then(res => {
			res.json().then((json) => {
				this.listCourier = json.data;
			});
		});

		authFetch("/payment/saldo/bank").then(res => {
			res.json().then((json) => {
				this.listBank = json.data;
			});
		});

		this.loadCS();
	},
	methods: {
		loadCS: function () {
			this.roles = this.$route.meta.roles;
			this.userInfo = auth.user();
			this.filter.datatype = this.data_type;
			authFetch("/shipper/usercs")
				.then((res) => {
						if (res.status === 201) {} else if (res.status === 400) {}
						return res.json();
				})
				.then((js) => {
						this.listCS = js.data;
				});
		},
		getKurirLogo(kurir) {
			const fileName = kurir.toLowerCase();

			return require(`../assets/img/${fileName}.png`); // the module request
		},
		handleClick(e) {
			if (e.target.matches("input[type=checkbox]")) {
				var checkboxes = document.querySelectorAll('.select-rekon:checked');
				if (checkboxes.length == 0) {
					this.$refs.checkall.checked = false;
				} else {
					this.$refs.checkall.checked = true;
				}

				this.selectedCount = checkboxes.length;
				if (this.selectedCount > 0) {
					$(this.$refs.btnSelected).show();
				} else {
					$(this.$refs.btnSelected).hide();
				}
				return false;
			} else if (e.target.matches(".rekon-detail")) {
				let route = this.$router.resolve({
					path: "/rekonsiliasi/riwayat/" + e.target.dataset.id
				});
				window.open(route.href,"_blank");
				// return false;
			}
		},
		selectFilter() {
			$(this.$refs.filterDialog).modal("toggle");
		},
		getFilter(event) {
			this.filter_courier = [];
			for (const key of this.filter.courier) {
				this.filter_courier.push(this.listCourier.find((e) => e.courier_id == key));
			}
			this.filter_bank = [];
			for (const key of this.filter.bank) {
				this.filter_bank.push(this.listBank.find((e) => e.rekening_id == key));
			}
			this.table.api().ajax.reload();
			$(this.$refs.filterDialog).modal("toggle");
			event.preventDefault();
		},
		removeFilterCourier(value) {
			this.filter_courier = this.filter_courier.filter((e) => e.courier_id !== value);
			this.filter.courier = this.filter.courier.filter((e) => e !== value);
			this.table.api().ajax.reload();
		},
		removeFilterBank(value) {
			this.filter_bank = this.filter_bank.filter((e) => e.rekening_id !== value);
			this.filter.bank = this.filter.bank.filter((e) => e !== value);
			this.table.api().ajax.reload();
		},
		clearFilter() {
			this.filter.courier = [];
			this.filter_courier = [];
			this.filter.bank = [];
			this.filter_bank = [];
			this.table.api().ajax.reload();
		},
		selectAll: function (e) {
			var val = e.target.checked;
			var checkboxes = document.querySelectorAll('.select-rekon');

			for (var i = 0; i < checkboxes.length; i++) {
				checkboxes[i].checked = val;
			}

			if (val && checkboxes.length > 0) {
				this.selectedCount = checkboxes.length;
			} else {
				this.selectedCount = 0;
			}

			if (this.selectedCount > 0) {
				$(this.$refs.btnSelected).show();
			} else {
				$(this.$refs.btnSelected).hide();
			}
		},
		downloadXlsx: function (e) {
			this.loading = true;
			var table = $('#tableRiwayatRekonsiliasi').DataTable();
			var length = table.page.info().recordsTotal;

			if (length > 20000) {
				Swal.fire("Proses gagal", `Maksimal data AWB sebanyak kurang lebih 20.000 data.`, "error");
				this.loading = false;
				return false;
			}

			var filter = JSON.parse(JSON.stringify(this.filter));
				filter.q = $("input[name=q]").val();
				filter.category = $("select[name=category]").val();

			console.log(filter);
			var data = Object.keys(filter)
				.map(
					(key) =>
					encodeURIComponent(key) + "=" + (filter[key] === false ? 0 : encodeURIComponent(filter[key]))
				)
				.join("&");
			console.log(data);
			this.loadingContent = true;
			authFetch("/report/rekonsiliasi/reconciled_kurir_excell", {
				method: "POST",
				body: data,
			})
			.then((response) => {
				if (response.status === 201) {
				} else if (response.status === 400) {
				} else if (response.status === 500){
					Swal.fire("Timeout!", ``, "error");
					this.loading2 = false;
					this.msg = '';
					return false;
				}

				return response.blob();
			})
			.then((blob) => {
				setTimeout(() => {
					this.loading = false;
				}, 1000);
				this.loadingContent = false;
				var url = window.URL.createObjectURL(blob);
				var a = document.createElement("a");
				a.href = url;
				a.download = "Riwayat Rekonsiliasi Kurir.xlsx";
				document.body.appendChild(a); // we need to append the element to the dom -> otherwise it will not work in firefox
				a.click();
				a.remove(); //afterwards we remove the element again
			});
		}
	},
	mounted() {
		const e = this.$refs;
		var self = this;
		new Daterangepicker(
			this.$refs.daterange,
			{
				startDate: !this.dt1 ? moment().day(-31) : this.dt1,
				endDate: !this.dt2 ? moment() : this.dt2,
				locale: {
					format: 'DD/MM/YYYY'
				},
			},
			function (d1, d2) {
				self.dateStart = d1.format("YYYY-MM-DD");
				self.dateEnd = d2.format("YYYY-MM-DD");
				self.data_type = self.data_type;
				self.filter.datatype = self.data_type;
				self.filter.dt1 = self.dateStart;
				self.filter.dt2 = self.dateEnd;
				self.table.api().ajax.reload();
			}
		);
		//console.log('roles', this.$route.params.roles);
		this.table = createTable(e.tableRiwayatRekonsiliasi, {
			title: "",
			roles: this.$route.params.roles,
			ajax: "/order/rekonsiliasi/reconciled_kurir",
			frame: false,
			toolbarEl: e.toolbar,
			fixedColumns: {
				start: 1,
				end: 1
			},
			scrollCollapse: true,
			scrollX: true,
			processing: true,
			autoWidth:false,
			language: {
				processing: '<i class="fa fa-spinner fa-5x fa-spin" style="font-size:50px;color:black;"></i>'
			},
			filterBy: [],
			filter: false,
			order: [[1,'desc']],
			paramData: function (d) {
				var filter = JSON.parse(JSON.stringify(self.filter));
				//d = filter;
				//d.dev = filter.delivered;
				d.dt1 = self.dateStart;
				d.dt2 = self.dateEnd;
				d.datatype = self.data_type;
				var filter = JSON.parse(JSON.stringify(self.filter));
				for (const key in filter) {
						d[key] = filter[key] === false ? 0 : filter[key];
				}
			},
			columns: [
				{
					data: "id",
					render: function (data, type, row, meta) {
						return `<input type="checkbox" class="select-rekon" value="` + data + `" />`
					},
					visible: false
				},
				{
					data: "tanggal",
					render: function (data, type, row, meta) {
						return !data ? '-' : moment(data).format("DD/MM/YYYY HH:mm");
					}
				},
				{ data: "kurir" },
				{ data: "rekening" },
				{ data: "jumlah" },
				{
					data: "id",
					render: function (data, type, row, meta) {
						return `<div class="btn btn-link btn-lg p-0 text-warning"><i class="fi fi-rr-eye cursor-pointer rekon-detail" data-id="` + data + `"></i></div>`;
					}
				}
			],
			rowCallback: function (row, data) {
			},
			initComplete: function () {
				$('.loading-overlay').removeClass('show');
			},
		});
		
	},
};
</script>
<style type="text/css">
:root {
	--bs-gap: 0;
}

#tableRiwayatRekonsiliasi_wrapper .col-md-6:nth-child(1) {
	-ms-flex-preferred-size: 0;
	flex-basis: 0;
	-ms-flex-positive: 1;
	flex-grow: 1;
	max-width: 100%;
}

#tableRiwayatRekonsiliasi_wrapper .col-md-6:nth-child(2) {
	-ms-flex: 0 0 auto;
	flex: 0 0 auto;
	width: auto;
	max-width: 100%;
}

@media screen and (max-width: 767px) {
	#tableRiwayatRekonsiliasi_length {
		text-align: left;
	}
}

.dataTables_filter {
	padding-right: 0;
}

.table-bordered th, .table-bordered td {
	border-left: 0;
	border-right: 0;
}

.undefined_caption {
	visibility: hidden;
}
</style>